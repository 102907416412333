/* ********* Global Color styling for the full app ********* */
:root {
  --primary-green: #40e376;
  --secondary-blue: #23254f;
  --container-grey: #f7f8fa;
  --white: #fff;
  --black: #000;
  --line-grey: #d7e0e9;
  --link--blue: #2f80ed;

  /* Grey color palette (Stripe) */
  --color-grey-50: #f7fafc;
  --color-grey-100: #e3e8ee;
  --color-grey-dark-100: #7a8394;
  --color-grey-200: #c1c9d2;
  --color-grey-300: #a3acb9;
  --color-grey-400: #8792a2;
  --color-grey-500: #697386;
  --color-grey-600: #59617b;
  --color-grey-700: #3c4257;
  --color-grey-800: #2a2f45;
  --color-grey-900: #1a1f36;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.header {
  /* border-bottom: 1px solid var(--line-grey); */
}

.header_logo {
  width: 165px;
}

.header img {
  width: 180px;
}

.link,
a {
  color: var(--link--blue);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 8px;
}

.text-black {
  color: #000000;
}

.text-grey {
  color: var(--color-grey-400) !important;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-column {
  flex-direction: column;
}

.top {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bottom {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.gap-xs {
  gap: 8px;
}

.width-100 {
  width: 100%;
}

.fw-500 {
  font-weight: 500 !important;
}

.p-xs {
  padding: 8px;
}
.p-sm {
  padding: 1rem 3rem !important;
}
.p-smd {
  padding: 24px;
}
.p-md {
  padding: 32px !important;
}
.p-lg {
  padding: 48px;
}

.m-xs {
  margin: 8px !important;
}
.m-sm {
  margin: 16px !important;
}
.m-smd {
  margin: 24px !important;
}

.br-8 {
  border-radius: 8px;
}

.text-center {
  text-align: center;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 100;
}

.single-spaced,
.single-spaced * {
  line-height: 1;
}

.MuiTypography-gutterBottom {
  margin-bottom: 8px !important;
}

@media (max-width: 1024px) {
  .desktop {
    display: none;
  }
}

.MuiStepIcon-text {
  fill: #fff !important;
}

.stepper__wrapper {
  /* max-width: 1440px; */
  /* margin: auto;
  margin-top: 85px; */
  padding-top: 100px;
}

.stepper__numbers {
  max-width: 1024px;
  margin: auto;
}

.stepper__content {
  /* border: 1px solid red; */
  /* max-width: 1024px; */
  max-width: 768px;
  margin: auto;
}

.stepper__content__formFields {
  padding: 2rem 0;
}
.forms_margin {
  padding: 1rem 0;
}

.stepper__content__wrapper {
  /* padding-bottom: 3rem; */
  max-height: calc(100vh - 100px);
}

.showShadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.border {
  border: 1px solid red;
}

.form__btnWrapper {
  display: flex;
  padding-top: 1.5rem;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}

/* --------------- CLINIC DETAILS ------------- */

/* -------------------------------------------- */

/* ------------- PROVIDERS ------ */
.container {
  width: 100%;
  background: var(--container-grey);
  min-height: 300px;
  border: 1px solid var(--line-grey);
  border-radius: 8px;
}

.container__header {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 8px 24px;
}

.container__listItems {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.container__listItem {
  background: #fff;
  border: 1px solid var(--line-grey);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.icon_delete_verify {
  display: flex;
}

.container__listItemDetails {
  display: flex;
  gap: 1rem;
}

.dialogue__container {
  padding: 1.5rem !important;
}

.MuiDialogContent-root {
  overflow-y: hidden !important;
}

/* @media only screen and (max-width: 600px) {
.stepper_component{
	display: none;
}
} */

/* .MuiCollapse-root.MuiCollapse-entered {
	padding: 16px !important;
} */

/* ------------------------------------------ */

/* ------------- UPLOAD ------ */

/* ------------------------------------------ */
.loaderDiv {
  margin-top: 200px;
}

.footer {
  /* height: 60px; */
  display: flex;
  /* background-color: #f3f8f9; */
  border-top: 2px solid #f2f2f2;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* padding-top: 40px; */
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #ebf1fc;
  padding: 0.8rem 0;
}

.footer_content {
  font-size: 14px;
  line-height: 24px;
  color: #828282;
  padding: 0 1rem;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.footer_item {
  display: flex;
  justify-content: center;
}

.footer_link {
  font-size: 14px;
  line-height: 24px;
  color: #828282 !important;
  text-decoration: none !important;
}
